@use "@angular/material" as mat;

:root {
  --primary-color: #{$primary-color};
  --second-color: #{$second-color};
  --third-color: #{$third-color};
  // * for RGBA
  --rgb-primary-color: #{$rgb-primary-color}; //--primary-color *
  --rgb-second-color: #{$rgb-second-color}; //--second-color *
  --rgb-third-color: #{$rgb-third-color}; //--third-color *
}