// TODO: Definir los colores primarios antes de llamar a vars.scss para que MATERIAL genere una paleta de colores según los primeros 2 colores que definimos aquí
$primary-color: #00529C;
$second-color: #00529C;
$third-color: #A5CD39;

$rgb-primary-color: 0, 82, 156; //--primary-color *
$rgb-second-color: 0, 82, 156; //--second-color *
$rgb-third-color: 165, 205, 57; //--third-color *

@import "./vars.scss"; // ruta del archivo de variables

// * ---------- Fonts
@import url('https://fonts.googleapis.com/css2?family=Asap+Condensed:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body.webapp-site {
    --font-title: 'Asap Condensed', sans-serif;
    --font-subtext: 'Inter', sans-serif;
    --font-text: 'Inter', sans-serif;
	background-color: #F1F4F6;

	.dropdown_menu_cy-item {
        .dropdown_menu_cy-action {
            font-family: var(--font-title);
        }
    }

    .cy__btn {
        font-family: var(--font-title);
        font-weight: 500;
        &.mdc-button .mdc-button__label {
            font-family: var(--font-title);
            font-weight: 500;
        }
    }

	.custom__card-1 {
        padding: 1rem;
        .content__img {
            width: 100%;
            display: block;
            border-radius: 8px;
            background-color: rgba($third-color, 0.2);
            &.default__ctt-image {
                min-height: 210px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    margin: auto;
                    border-radius: 0;
                }
            }
            img {
                width: 100%;
                display: block;
                border-radius: 8px;
            }
        }
        .content__desc {
            padding: 1rem 0;
            .subtext__card {
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                margin: 0;
                margin-bottom: 10px;
            }
            .title__card {
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                margin: 0;
                margin-bottom: 10px;
            }
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
        .content__btns {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
			.btn_buy {
				background-color: var(--second-color);
				color: #fff;
				text-transform: uppercase;
				font-family: var(--font-title);
			}
        }
    }

	.custom__card-select {
        padding: 1rem;
        border: 2px solid #EAECF0;
        border-radius: 8px;
        width: 100%;
        cursor: pointer;
        user-select: none;
        &.active {
            pointer-events: none;
            cursor: default;
            border-color: var(--second-color);
            background-color: rgba($second-color, 0.025);
            .content__head span {
                border-color: var(--second-color);
                &::before {
                    transform: scale(1);
                }
            }
        }
        .content__head {
            width: 100%;
            display: grid;
            grid-template-columns: minmax(0,20px) minmax(0,1fr);
            gap: 10px;
            align-items: center;
            span {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid #EAECF0;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: var(--second-color);
                    transition: all ease-in-out 0.2s;
                    transform: scale(0);
                    transform-origin: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .title__card {
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                margin: 0;
            }
        }
        .content__desc {
            padding: 1rem 0;
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
    }

	.social__auth {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		.btn__outline.cy__btn {
			min-width: unset;
			border-radius: 50%;
			padding: 0;
			width: 50px;
			height: 50px;
			margin: 5px !important;
			.mdc-button__label .span__text {
				display: none;
			}
		}
	}

	.register__profile {
		background-color: #fff;
	}

	.profile__settings {
        background-color: #fff;
        .title__ps, .current__profile-ps {
            color: var(--second-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-family: var(--font-title);
        }
		.cy__btn.mdc-button.btn__second.btn__change-profile {
            border-color: var(--third-color);
            background-color: var(--third-color);
            color: white;
        }
    }
    .process_cart {
        background-color: #fff;
        .title__pc {
            text-transform: uppercase;
            font-family: var(--font-title);
            font-size: 1.325rem;
            line-height: 1.75rem;
            font-weight: 600;
        }
        .subtitle__pc {
            font-size: 16px;
            line-height: normal;
            font-weight: 500;
        }
        .title__pc, .subtitle__pc {
            color: var(--second-color);
        }
        .card__pc {
            border-radius: 0.75rem;
        }
        .order__pc {
            border: 1px solid #e5e7eb;
            border-radius: 0.75rem;
            color: var(--second-color);
            table {
                th {
                    background-color: #f9fafb;
                    border-bottom: 1px solid #e5e7eb;
                }
                td {
                    border-bottom: 1px solid #e5e7eb;
                    color: #000;
                }
            }
        }
        .order-total__pc {
            border-radius: 0.75rem;
            color: var(--second-color);
            background-color: #EAECF0;
            font-weight: 500;
        }
        .btn__pay {
            background-color: var(--second-color);
            color: #fff;
        }
        .btn__pc {
            background-color: var(--second-color);
            color: #fff;
        }
        .input_group:not(.no_space) {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }

	.content__pcs {
        width: 1024px;
        display: block;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;
        max-width: 100%;
        background: #fff;
        padding: 1rem 1.5rem;
        .content__step-pcs {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            &.active__step {
                .icon__step-pcs {
                    .ctt__icon-pcs {
                        background-color: var(--second-color);
                    }
                }
                .title__pcs {
                    color: var(--primary-color);
                }
            }
            .icon__step-pcs {
                .ctt__icon-pcs {
                    background-color: #fff;
                    box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.102);
                    margin-bottom: 10px;
                }
            }
            .title__pcs {
                font-family: var(--font-title);
                text-transform: uppercase;
                color: var(--second-color);
            }
        }
    }

    .payments {
        background-color: #fff;
        .title__payments {
            color: var(--second-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            font-family: var(--font-title);
        }
    }

    .card-general {
        .btns__card-account {
            text-align: left;
        }
    }

	.content__payment-card, .content__payment-card-double-sided, .payment-card-add {
		--color-card-payment-text: var(--second-color);
	}

    .resp__table {
        table:not(.custom-table-design):not(.mat-calendar-table) {
            border-radius: 0;
            th {
                font-size: 14px;
                font-weight: 500;
                background: #F9FAFB;
            }
        }
    }

    .auth__content {
        .input_group {
            input, select {
                color: #000;
            }
        }
    }

    .content__item-pay {
        padding: 10px 15px 10px 5px;
        width: calc(100% - (10px * 2));
        margin: 0 auto;
        display: grid;
        grid-template-columns: minmax(0, 40px) minmax(0, 1fr);
        align-items: center;
        background-color: rgba(0, 82, 156, 0.05);
        border-radius: 5px;
        &.type__2 {
            display: block;
        }
        .content__item-pay-detail {
            width: 100%;
            padding-left: 10px;
            h5, p {
                font-size: 12px;
                line-height: normal;
                margin: 0;
            }
            h5 {
                font-weight: 600;
            }
        }
    }

    .content__footer-pay {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white;
        &:has(.content__footer-pay-btn) {
            padding: 1rem;
        }
        .content__footer-pay-btn {
            width: 100%;
        }
    }

    main:has(.content__footer-pay-btn) {
        padding-bottom: calc(1rem + 4rem);
    }
}